import React, { useRef, useEffect } from 'react';
import Konva from "konva";
import { Stage, Layer,Transformer,Image, Rect } from 'react-konva';
import { useLocation } from 'react-router-dom';
import { useKonvaModule } from '../components/KonvaModule';

//能够导出每个图层
//添加图层，例如文本图层，能够有模板，地址、手机、文本、经营范围等
//可选印刷点，投诉这个印刷点和评价
//实现与陶特链接，自动化提交订单

const template = {
  'pages':[
    {
      'id':1,
      'title':"正面",
      'layer':[{
        "id":1,
        "type": 0,
        "x": 293,
        "y": 218,
        "url": "/storage/card/1/logo1.png",
        "width": 498,
        "height": 148,
        "opacity": 1,
        "zIndex":0,
        "name":"",
        "sign":""
      },{
        "id":2,
        "type": 1,
        "x": 293,
        "y": 218,
        "text": "",
        "width": 498,
        "height": 148,
        "opacity": 1,
        "color":"#333333",
        "zIndex":0,
        "fontWeight":"",
        "fontSize":"",
        "fontUrl":"",
        "fontName":"",
        "fontStyle":"",
        "lineHeight":"",
        "letterSpacing":"",
        "textAlign":"",
        "name":"",
        "sign":""
      }],
      'backgroundColor':'#ffffff',
      'backgroundImage':'',
    },
    {
      'id':2,
      'title':"反面",
      'element':[{

      }],
      'backgroundColor':'#ffffff',
      'backgroundImage':'',
    }
  ],
  'loadTime':0,
  'rotatable':false,
  'printable':true,
};

const App = () => {
  const scale = 0.3;  // 缩放比例
  const query = new URLSearchParams(useLocation().search);
  const isMobile: boolean = !!query.get('isMobile');
  const { addElements,onDragMove,transformerStype } = useKonvaModule(isMobile,scale);
  const stageRef = useRef<Konva.Stage>(null);
  const layerRef = useRef<Konva.Layer>(null);
  const transformerRef = useRef<Konva.Transformer>(null);
  const moveIconRef = useRef<Konva.Image>(null);

  const moveIconSrc = new window.Image();
  moveIconSrc.src = '/images/move.png';

  useEffect(() => {

    //获得组件，可以修改组件
    const stage = stageRef.current;
    const layer = layerRef.current;
    const transformer = transformerRef.current;
    const moveIcon = moveIconRef.current;

    if (!stage || !layer || !transformer || !moveIcon) return;

    addElements(stage, layer,transformer,moveIcon);
    transformerStype(transformer);

    //点击画布的空白处时，清空transformer的节点
    stage.on('click', function (e) {
        if (e.target === stage) {
            transformer.nodes([]);
        }
    });

    layer.draw();

  }, [onDragMove]);

  return (
    <div style={{ position:"relative",display:'flex',alignItems:'center',justifyContent:'center',width:window.innerWidth,height:window.innerHeight,backgroundColor: "var(--background)"}}>
      <div style={{position:"absolute",top:0,left:0,right:0}}>
          <div style={{paddingLeft:'10px',paddingRight:'10px'}}>
              <div style={{backgroundColor:"var(--background5)",boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.3)',borderBottomLeftRadius: '10px',borderBottomRightRadius: '10px',padding:'10px'}}>
                {/* 页面：正面 | 添加元素 | 图层 | 返回 | 撤销 | 保存 | 印刷 | 分享 */}
                <div style={{display:'flex',alignItems:'center'}}>
                  <div style={{color:'var(--dark1)'}}>页面：</div>
                  <div style={{display:'flex',marginRight:'10px',alignItems:'center',border: '1px solid var(--gray5)',backgroundColor: "var(--background1)",paddingLeft:'10px',paddingRight:'10px',paddingTop:'5px',paddingBottom:'5px',borderRadius:'10px',cursor:'pointer'}}>
                      <span>正面</span>
                      <img src={process.env.PUBLIC_URL + '/images/icon01.png'} style={{width:'16px',height:'16px',marginLeft:'5px'}}/>
                  </div>
                  <div style={{display:'flex',marginRight:'10px',alignItems:'center',border: '1px solid var(--gray5)',backgroundColor: "var(--background1)",paddingLeft:'10px',paddingRight:'10px',paddingTop:'5px',paddingBottom:'5px',borderRadius:'10px',cursor:'pointer'}}>
                      <img src={process.env.PUBLIC_URL + '/images/icon03.png'} style={{width:'16px',height:'16px'}}/>
                      <span style={{marginLeft:'5px'}}>图层</span>
                  </div>
                  <div style={{display:'flex',marginRight:'10px',alignItems:'center',border: '1px solid var(--gray5)',backgroundColor: "var(--background1)",paddingLeft:'10px',paddingRight:'10px',paddingTop:'5px',paddingBottom:'5px',borderRadius:'10px',cursor:'pointer'}}>
                      <img src={process.env.PUBLIC_URL + '/images/icon04.png'} style={{width:'18px',height:'18px'}}/>
                  </div>
                  <div style={{display:'flex',marginRight:'10px',alignItems:'center',border: '1px solid var(--gray5)',backgroundColor: "var(--background1)",paddingLeft:'10px',paddingRight:'10px',paddingTop:'5px',paddingBottom:'5px',borderRadius:'10px',cursor:'pointer'}}>
                      <img src={process.env.PUBLIC_URL + '/images/icon05.png'} style={{width:'18px',height:'18px'}}/>
                  </div>
                  <div style={{display:'flex',marginRight:'10px',alignItems:'center',border: '1px solid var(--dangerBorderColor)',backgroundColor: "var(--danger)",paddingLeft:'10px',paddingRight:'10px',paddingTop:'5px',paddingBottom:'5px',borderRadius:'10px',cursor:'pointer'}}>
                      <span style={{color:'#ffffff'}}>印刷</span>
                  </div>
                  <div style={{display:'flex',marginRight:'10px',alignItems:'center',border: '1px solid var(--infoBorderColor)',backgroundColor: "var(--info)",paddingLeft:'10px',paddingRight:'10px',paddingTop:'5px',paddingBottom:'5px',borderRadius:'10px',cursor:'pointer'}}>
                      <span style={{color:'#ffffff'}}>导出</span>
                  </div>
                </div>

              </div>
          </div>
      </div>
      <Stage
          ref={stageRef}
          width={template.canvasWidth*scale}
          height={template.canvasHeight*scale}
          scaleX={scale}  // 设置水平缩放值为0.9
          scaleY={scale}  // 设置垂直缩放值为0.9
        >
          <Layer>
          <Rect
              width={template.canvasWidth}
              height={template.canvasHeight}
              fill="blue"
                />
          </Layer>

          <Layer ref={layerRef}>
            <Transformer ref={transformerRef} />
            <Image ref={moveIconRef} image={moveIconSrc} draggable x={-100} y={-100} width={24} height={24} scaleX={1/scale} scaleY={1/scale}/>
          </Layer>
          
      </Stage>

    </div>
  );
}

export default App;