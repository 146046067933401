import { useCallback,useRef } from 'react';
import Konva from "konva";
import { useGuideLine } from '../components/GuideLine';
import {loadFonts,filterFields,loadImage} from '../components/Common';
import { Shape, ShapeConfig } from 'konva/lib/Shape';

export const useKonvaModule = (isMobile:boolean = false,scale:number = 1) => {
  const stageRef = useRef<Konva.Stage>(null);
  const stageRefs = useRef<(Konva.Stage | null)[]>([]);
  const layerRef = useRef<Konva.Layer>(null);
  const transformerRef = useRef<Konva.Transformer>(null);
  const moveIconRef = useRef<Konva.Image>(null);
  const moveIcon = new window.Image();
  moveIcon.src = '/images/move.png';

    const { drawGuides,getGuides,getObjectSnappingEdges,getLineGuideStops} = useGuideLine();

    //定义记忆化的函数
    //target  是辅助移动工具，传入的要移动的目标，辅助移动工具，本身不用e而是target
    const onDragMove = useCallback((e: Konva.KonvaEventObject<DragEvent>,target: Konva.Shape|null = null) => {
          target = target || (e.target as Konva.Shape);

          const layer = target.getLayer();
    
          // 清除屏幕上以前的所有行
          if (layer !== null) {
            layer.find(".guid-line").forEach((l: Konva.Node) => l.destroy());
          }
          
          // 查找可能的捕捉线
          const lineGuideStops = getLineGuideStops(target as Konva.Shape);
          // 查找当前对象的捕捉点
          const itemBounds = getObjectSnappingEdges(target as Konva.Shape);
    
          // 现在找到在哪里可以捕捉当前对象
          const guides = getGuides(lineGuideStops, itemBounds);
    
          // 如果没有捕捉，什么也不做
          if (!guides.length) {
            return;
          }

          if (layer !== null) {
            drawGuides(guides, layer);
          }
    
          const absPos = target.absolutePosition();
          // 现在强制对象位置
          guides.forEach((lg) => {
            switch (lg.snap) {
              case "start": {
                switch (lg.orientation) {
                  case "V": {
                    absPos.x = lg.lineGuide + lg.offset;
                    break;
                  }
                  case "H": {
                    absPos.y = lg.lineGuide + lg.offset;
                    break;
                  }
                }
                break;
              }
              case "center": {
                switch (lg.orientation) {
                  case "V": {
                    absPos.x = lg.lineGuide + lg.offset;
                    break;
                  }
                  case "H": {
                    absPos.y = lg.lineGuide + lg.offset;
                    break;
                  }
                }
                break;
              }
              case "end": {
                switch (lg.orientation) {
                  case "V": {
                    absPos.x = lg.lineGuide + lg.offset;
                    break;
                  }
                  case "H": {
                    absPos.y = lg.lineGuide + lg.offset;
                    break;
                  }
                }
                break;
              }
            }
          });
          target.absolutePosition(absPos);
        },
    [drawGuides, getGuides, getObjectSnappingEdges]);

    const onDragEnd = (e: Konva.KonvaEventObject<DragEvent>,target: Konva.Shape|null = null) => {
        target = target || (e.target as Konva.Shape);
        const layer = target.getLayer();
        if (layer !== null) {
            layer.find(".guid-line").forEach((l: Konva.Node) => l.destroy());
        }
    };
    const initElements = async (config:any) => {
      try {
       var fonts = filterFields(config.layer,['fontName', 'fontUrl']);
        
        await loadFonts(fonts).then(res => {
          console.log(res); // "字体全部加载完成"
        }).catch(err => {
          throw new Error(err);
        });

        for (const value of config.layer) {

          var rect: any;

          if(value.type == 1){

              rect = new Konva.Text({
                x: value.x, 
                y: value.y, 
                text: value.text,
                fontSize: value.fontSize, 
                fontFamily: value.fontName,
                fill: value.color,
                draggable: true,
                visible: value.visible,
              });

              
              //拖动中
              rect.on("dragmove", (e: Konva.KonvaEventObject<DragEvent>) =>
                  onDragMove(e)
              );
              //拖动结束
              rect.on("dragend", (e: Konva.KonvaEventObject<DragEvent>) =>
                  onDragEnd(e)
              );

          }else if(value.type == 2){

              let imageObj = await loadImage(value.url);
              
              rect = new Konva.Image({
                image: imageObj,
                x: value.x,
                y: value.y,
                width: value.width,
                height: value.height,
                draggable: true,
                visible: value.visible,
              });
          }


          //点击时添加变形工具
          rect.on('click', function () {

            config.transformerRef.nodes([rect]);
            config.transformerRef.moveToTop(); // 将变形工具移动到最前面

            if(isMobile){
              //辅助移动工具
              let moveIconPosition = 30/scale;
              config.moveIconRef.x(rect.x() + rect.width() + moveIconPosition);
              config.moveIconRef.y(rect.y() + rect.height() + moveIconPosition);
              config.moveIconRef.moveToTop();

              // 重新绑定dragmove和dragend事件
              config.moveIconRef.off('dragmove');
              config.moveIconRef.on('dragmove', function (e: Konva.KonvaEventObject<DragEvent>) {
                rect.x(config.moveIconRef.x() - rect.width() - moveIconPosition);
                rect.y(config.moveIconRef.y() - rect.height() - moveIconPosition);
                onDragMove(e, rect);

              });
              config.moveIconRef.off('dragend');
              config.moveIconRef.on('dragend', function (e: Konva.KonvaEventObject<DragEvent>) {
                onDragEnd(e, rect);
              });

            }

          });

          config.layerRef.add(rect);

        }

        config.moveIconRef.on('mousedown', function () {
          config.transformerRef.nodes([]);
        });

        transformerStype(config.transformerRef);

        //点击画布的空白处时，清空transformer的节点
        config.stageRef.on('click', function (e) {
          if (e.target === config.stageRef) {
            config.transformerRef.nodes([]);
          }
        });

        config.layerRef.draw();

      } catch (err) {
        console.error('initElements函数执行失败:', err);
      }

    }

    const addTextElements = (config:object) => {
        console.log(config);
    }

    const addElements = (stage: Konva.Stage, layer: Konva.Layer, transformer: Konva.Transformer,moveIcon:Konva.Image) => {

      //随机绘制5个方块
      for (let i = 0; i < 5; i++) {
          const rect = new Konva.Rect({
              x: Math.random() * stage.width(),
              y: Math.random() * stage.height(),
              width: 50 + Math.random() * 50,
              height: 50 + Math.random() * 50,
              fill: Konva.Util.getRandomColor(),
              draggable: true,
              name: "object"
          });
  
          //拖动中
          rect.on("dragmove", (e: Konva.KonvaEventObject<DragEvent>) =>
              onDragMove(e)
          );
          //拖动结束
          rect.on("dragend", (e: Konva.KonvaEventObject<DragEvent>) =>
              onDragEnd(e)
          );

          //点击时添加变形工具
          rect.on('click', function () {

            transformer.nodes([rect]);
            transformer.moveToTop(); // 将变形工具移动到最前面

            //辅助移动工具
            let moveIconPosition = 30/scale;
            if(isMobile){
              moveIcon.x(rect.x() + rect.width() + moveIconPosition);
              moveIcon.y(rect.y() + rect.height() + moveIconPosition);
            }
            moveIcon.moveToTop();

            // 重新绑定dragmove和dragend事件
            moveIcon.off('dragmove');
            moveIcon.on('dragmove', function (e: Konva.KonvaEventObject<DragEvent>) {
              rect.x(moveIcon.x() - rect.width() - moveIconPosition);
              rect.y(moveIcon.y() - rect.height() - moveIconPosition);
              onDragMove(e, rect);

            });
            moveIcon.off('dragend');
            moveIcon.on('dragend', function (e: Konva.KonvaEventObject<DragEvent>) {
              onDragEnd(e, rect);
            });

          });

          layer.add(rect);
      }

      moveIcon.on('mousedown', function () {
        transformer.nodes([]);
      });

    };

    // 设置 Transformer 的样式
    const transformerStype = (transformer: Konva.Transformer) => {
      transformer.anchorStroke('#00ff00'); // 锚点描边颜色
      transformer.borderStroke('#00ff00'); // 边框描边颜色
      transformer.borderDash([5, 5]); // 边框虚线样式
      transformer.rotationSnaps([0, 90, 180, 270]); //旋转角度吸附

      if(isMobile){
        transformer.anchorSize(20); // 锚点大小
        transformer.anchorFill('#e7ffe7'); // 锚点填充色
        transformer.padding(20);
        transformer.anchorCornerRadius(10);
      }
    }
  
    return {
      onDragMove,
      onDragEnd,
      initElements,
      addTextElements,
      transformerStype,
      stageRefs,
      layerRef,
      transformerRef,
      moveIconRef,
      moveIcon
    };
};
