import React from 'react';
import { LoadingProps } from '../components/Interface';

const Loading: React.FC<LoadingProps> = ({ isLoading }) => {
  if (!isLoading) {
    return null;
  }

  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'fixed',
      top: 0,
      left: 0,
      zIndex: 9999,
      bottom:0,
      right:0
    }}>
        <div style={{width:'100px',height:'100px',display: 'flex',justifyContent: 'center',alignItems: 'center',flexDirection:'column',backgroundColor:'#000000',borderRadius:'10px'}}>
            <img src="/images/loading.gif" style={{width:'40px',height:'36px'}} alt="Loading..." />
            <span style={{color:'#ffffff',fontSize:'14px'}}>Loading..</span>
        </div>
    </div>
  );
};

export default Loading;