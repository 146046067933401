import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Edit from './pages/Edit';
import UploadTemplate from './pages/UploadTemplate';

import { toastConfig } from 'react-simple-toasts';
import 'react-simple-toasts/dist/theme/dark.css'; // 导入 dark 主题的 CSS
toastConfig({ theme: 'dark' }); // 设置全局的 toast 主题

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/edit" element={<Edit />} />
        <Route path="/uploadTemplate" element={<UploadTemplate />} />
      </Routes>
    </Router>
  );
};

export default App;
