import React from 'react';
import Konva from 'konva';
import { Layer, Rect, Image as KonvaImage} from 'react-konva';
import toast from 'react-simple-toasts';
import { pages } from '../components/Interface';
var site = "https://heyiliao.shuzao.net";
export const API = {
    site:site,
    font:'https://font.shuzao.net'
};
export const sliceText = (inputString:string,length:number) => {
    if (inputString.length <= length) {
        return inputString;
        } else {
        return inputString.slice(0, length) + "...";
    }
}
export const implode = (glue, pieces) => {
    var i = '',
        retVal = '',
        tGlue = '';
    if (typeof pieces === 'object') {
        if (Object.prototype.toString.call(pieces) === '[object Array]') {
            return pieces.join(glue);
        }
        for (i in pieces) {
            retVal += tGlue + pieces[i];
            tGlue = glue;
        }
        return retVal;
    }
    return pieces;
}
export const setRgbTo16 = (str:string) => {
    var rgb = str.split(',');
    var r = parseInt(rgb[0].split('(')[1]);
    var g = parseInt(rgb[1]);
    var b = parseInt(rgb[2].split(')')[0]);
    var hex = "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
    return hex;
}
export const checkImageMonochrome = (dataUrl:string) => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = () => {
      const stage = new Konva.Stage({
        container: 'checkImageMonochrome',
        width: image.width,
        height: image.height,
      });

      const layer = new Konva.Layer();
      stage.add(layer);

      const konvaImage = new Konva.Image({
        image: image,
        x: 0,
        y: 0,
      });

      layer.add(konvaImage);
      layer.draw();

      const imageData = layer.getContext().getImageData(0, 0, image.width, image.height);
      const data = imageData.data;

      let color = `${data[0]},${data[1]},${data[2]},${data[3]}`;

      for (let i = 0; i < data.length; i += 4) {
        if (`${data[i]},${data[i+1]},${data[i+2]},${data[i+3]}` !== color) {
          resolve(null);
          return;
        }
      }

      resolve(color);
    };

    image.onerror = reject;
    image.src = dataUrl;
  });
}
export const getBackground = async (dataUrl:string) => {
  try {
    const color = await checkImageMonochrome(dataUrl);
    if (color) {
      return {
        'type':2,
        'color':setRgbTo16('rgb('+color+')')
      };
    } else {
      return {
        'type':1,
        'url':dataUrl
      };
    }
  } catch (error) {
    return {
      'type': 0,
      'error': '无法加载或处理背景图片'
    };
  }
}

export const analysisPsdFile = (file:string | ArrayBuffer | null | undefined) => {
    const fonts = {
      "FZHTJW--GB1-0": {
          "ename": "FZHTJW--GB1-0",
          "url": "/storage/font/FangZhengHeiTiJianTi-1.ttf"
      },
      "Fontquan-XinYiJiXiangSong-Regular": {
        "ename": "Fontquan-XinYiJiXiangSong-Regular",
        "url": "/storage/font/ZiTiQuanXinYiJiXiangSong-2.ttf"
      },
      "SJxingkai-C-Regular": {
        "ename": "SJxingkai-C-Regular",
        "url": "/storage/font/SanJiXingKaiJianTi-Cu-2.ttf"
      },
      "SmileySans-Oblique": {
        "ename": "SmileySans-Oblique",
        "url": "/storage/font/SmileySans-Oblique-2.ttf"
      },
      "FZKTJW--GB1-0": {
        "ename": "FZKTJW--GB1-0",
        "url": "/storage/font/FangZhengKaiTiJianTi-1.ttf"
      },
      "AZPPT_1_1436212_19": {
        "ename": "AZPPT_1_1436212_19",
        "url": "/storage/font/AZhuPaoPaoTi-2.ttf"
      },
      "baotuxiaobaiti": {
        "ename": "baotuxiaobaiti",
        "url": "/storage/font/BaoTuXiaoBaiTi-2.ttf"
      },
      "WQSFT_1_1306613_10": {
        "ename": "WQSFT_1_1306613_10",
        "url": "/storage/font/WangQiangShuFaTi-2.ttf"
      },
      "zcoolwenyiti": {
        "ename": "zcoolwenyiti",
        "url": "/storage/font/ZhanKuWenYiTi-2.ttf"
      },
      "YOUSHEhaoshenti": {
        "ename": "YOUSHEhaoshenti",
        "url": "/storage/font/YSHaoShenTi-2.ttf"
      },
      "Pangmenzhengdaoqingsongti": {
        "ename": "Pangmenzhengdaoqingsongti",
        "url": "/storage/font/YSHaoShenTi-2.ttf"
      },
      "hongleixingshu": {
        "ename": "hongleixingshu",
        "url": "/storage/font/HongLeiXingShuJianTi-2.otf"
      },
      "FZSSJW--GB1-0": {
        "ename": "FZSSJW--GB1-0",
        "url": "/storage/font/storage/font/FangZhengShuSongJianTi-1.ttf"
      },
      "TsangerXWZ": {
        "ename": "TsangerXWZ",
        "url": "/storage/font/CangErXiaoWanZi-2.ttf"
      },
      
    };

    return globalThis.SET_TEMPLATE.fromURL(file).then(async function(data) {
      
      var res = {
        layer: [] as object[], //as 关键字用于类型断言,告诉编译器，我们创建的空数组 [] 应该被视为 object[] 类型，layer: [] as Object[] 这句代码的意思是，先给layer赋值一个[],再断言为Object类型
        background: {},
        canvasWidth:0,
        canvasHeight:0
      };

      let tree = data.tree();
      let node  = tree.descendants();
      res.canvasWidth = tree.export().document.width;
      res.canvasHeight = tree.export().document.height;

      let nodeBackground = node.pop();
      let Base64Background = nodeBackground.get('image').toBase64();

      let background = await getBackground(Base64Background);
      if(background.type == 0){
        toast(background.error);
        return false;
      }

      res.background = background;

      for(let key in node){
        let layer = node[key].export();
        let typeTool = node[key].get('typeTool');

        if(layer.type == 'group' || layer.visible != true){
          continue;
        }

        if(layer.text != undefined ){
          if(layer.text.font.sizes == undefined){
            toast(sliceText(layer.name,10)+"：没有文字大小");
            return false;
          }
          let fontSize = layer.text.font.sizes[0];

          let letterSpacing = 0;
          if(typeTool.styles().Tracking != undefined){
            letterSpacing = typeTool.styles().Tracking[0];
          }

          let fontName = "";
          for(let value of layer.text.font.names){
              if(fonts[value] != undefined){
                fontName = value;
              }
          }
          if(!fontName){
            toast(sliceText(layer.name,10)+"：没有字体");
            return false;
          }

          //const { boundingBox,bounds } = typeTool.textData;
          //console.log(boundingBox);
          //console.log(bounds);

          const transform = layer.text.transform;
          fontSize = fontSize*transform.yy; //字体原始大小乘以变形工具变化文字的大小

          //判断是不是PS自动行高，如果是自动的，就给设置成固定值
          let lineHeight = 0;
          if(typeTool.styles().AutoLeading == undefined || typeTool.styles().AutoLeading[0] || typeTool.styles().AutoLeading[1]){
            lineHeight = 1.2;
          }else{
            lineHeight = layer.text.font.leading[0]?layer.text.font.leading[0]:layer.text.font.leading[1];
          }

          res.layer.push({
            'id':key+1,
            'type':1,
            'x':Math.round(layer.left),
            'y':Math.round(layer.top),  //四舍五入
            'width':Math.ceil(layer.width),  //Math.ceil 向上取整
            'height':Math.ceil(layer.height),
            'name':sliceText(layer.name,30),
            'color':setRgbTo16('rgb('+implode(',',layer.text.font.colors[0])+')'),
            'fontSize':Math.round(fontSize),
            'fontWeight':layer.text.font.weights[0],
            'fontStyle':layer.text.font.styles[0],
            'fontName':fontName,
            'fontUrl':fonts[fontName]['url'],
            'textDecoration':layer.text.font.textDecoration[0],
            'textAlign':layer.text.font.alignment[0] ?? 'left',
            'letterSpacing':letterSpacing.toFixed(1),
            'lineHeight':lineHeight.toFixed(1),
            'opacity':(layer.opacity % 1 === 0) ? layer.opacity : layer.opacity.toFixed(2),
            'visible':true,
            'text':layer.text.value,
            'zIndex':key,
          });

        }else{
          res.layer.push({
            'id':key+1,
            'name':sliceText(layer.name,30),
            'type':2,
            'x':Math.round(layer.left),
            'y':Math.round(layer.top),
            'width':layer.width,
            'height':layer.height,
            'opacity':(layer.opacity % 1 === 0) ? layer.opacity : layer.opacity.toFixed(2),
            'zIndex':key,
            'visible':true,
            'url':node[key].get('image').toBase64()
          });
        }

      }

      return res;
    });

  }
  export const setBackground = (page:pages) => {
    let layerContent;
    if (page.background.type == 1) {
      const image = new Image();
      image.src = page.background.url!; //使用“非空”断言操作符：!,告诉url绝对不是空
      layerContent = <KonvaImage width={page.canvasWidth} height={page.canvasHeight} image={image} />;
    } else if (page.background.type == 2) {
      layerContent = <Rect width={page.canvasWidth} height={page.canvasHeight} fill={page.background.color} />;
    }else{
      return;
    }
  
    return <Layer>{layerContent}</Layer>;
  }
  export const loadFonts = (fonts: { fontName: string; fontUrl: string }[]) => {
    return new Promise((resolve, reject) => {
      let loadedFonts = 0;
      for (const font of fonts) {
        const newFont = new FontFace(font.fontName, `url(${API.font}${font.fontUrl})`);
        newFont.load().then(() => {
          document.fonts.add(newFont);
          loadedFonts += 1;
          if (loadedFonts === fonts.length) {
            resolve('All fonts have been loaded!');
          }
        }).catch(() => {
          reject(`字体加载失败: ${font.fontName}`);
        });
      }
    });
  };
  export const filterFields = (arr: Array<any>, fields: Array<string>): Array<any> =>{
    return arr.reduce((newArr, obj) => {
      let newObj: any = {};
      let allFieldsExist = fields.every(field => obj.hasOwnProperty(field));
      if (allFieldsExist) {
          fields.forEach(field => {
              newObj[field] = obj[field];
          });
          newArr.push(newObj);
      }
      return newArr;
  }, []);
 }
 export const loadImage = (url:string): Promise<HTMLImageElement> => {
  return new Promise((resolve, reject) => {
    let imageObj = new Image();
    imageObj.src = url;
    imageObj.onload = () => resolve(imageObj);
    imageObj.onerror = reject;
  });
};