import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import toast from 'react-simple-toasts';
import { Stage, Layer,Transformer,Image } from 'react-konva';
import {analysisPsdFile,setBackground} from '../components/Common';
import Loading from '../components/Loading';
import { useKonvaModule } from '../components/KonvaModule';
import { pages } from '../components/Interface';

const App: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const {initElements,transformerStype,stageRefs, layerRef, transformerRef, moveIconRef, moveIcon } = useKonvaModule();

  const pageData = { 
    id: 1, 
    title: '', 
    layer:[],
    canvasWidth: 0,
    canvasHeight: 0,
    file: '',
    background:{type:0}
  };

  const [pages, setPages] = useState<pages[]>([pageData]);

  const addNewPage = () => {
    const newPageId = pages.length + 1;
    pageData.id = newPageId;
    setPages([...pages, pageData]);
  };

  const deletePage = (pageId: number) => {
    const updatedPages = pages.filter((page) => page.id !== pageId);
    setPages(updatedPages);
  };

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>, pageId: number) => {
    const updatedPages = pages.map((page) =>
      page.id === pageId ? { ...page, title: event.target.value } : page
    );
    setPages(updatedPages);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>, pageId: number) => {
    const file = event.target.files ? event.target.files[0] : null;
    const reader = new FileReader();
    reader.onload = function(e) {
      const updatedPages = pages.map((page) =>
        page.id === pageId ? { ...page, file: e.target?.result } : page
      );
      setPages(updatedPages);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleParse = async (index: number) => {

    if(pages[index].title.length <= 1 || pages[index].title.length > 5){
      toast('页面名称要求：2~5个字符');
      return false;
    }
    if(pages[index].file == null){
      toast('上传文件不能为空');
      return false;
    }
    let page = await analysisPsdFile(pages[index].file);
    let newPages = [...pages];
    newPages[index] = { ...newPages[index], ...page };
    
    setPages(newPages);
    console.log(newPages);
    initElements({
      'stageRef':stageRefs.current,
      'layerRef':layerRef.current,
      'transformerRef':transformerRef.current,
      'moveIconRef':moveIconRef.current,
      'layer':newPages[index].layer
    });

    
  };

  return (
    <div style={{ padding: '20px' }}>
      <div id="checkImageMonochrome" style={{position:'absolute',left:'-5000px'}}/>
      <Loading isLoading={loading} />
      <Helmet>
        <script src="/js/global.js" />
      </Helmet>
      {pages.map((page,index) => (
        <div key={page.id} style={{ border: '1px solid var(--gray4)', padding: '20px', borderRadius: '10px', marginBottom: '20px' }}>
          <div>页面 {index+1}</div>
          <div style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
            <span>页面名称：</span>
            <input type="text" placeholder='请输入页面名称' onChange={(e) => handleTitleChange(e, page.id)} />
          </div>
          <div style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
            <span>上传文件：</span>
            <input id="file" type="file" accept=".psd" onChange={(e) => handleFileChange(e, page.id)} />
          </div>
          <div style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
            <button onClick={() => handleParse(index)}>解析</button>
            <button style={{ marginLeft: 20 }} onClick={addNewPage}>
              +增加新页面
            </button>
            {pages.length > 1 && (
              <button
                style={{ marginLeft: 20 }}
                onClick={() => deletePage(page.id)}
              >
                删除页面
              </button>
            )}
          </div>
          <div style={{marginTop:'20px'}}>
              <Stage
              width={page.canvasWidth}
              height={page.canvasHeight}
              ref={ref => stageRefs.current[index] = ref}
              >
              {setBackground(page)}
              <Layer ref={layerRef}>
                <Transformer ref={transformerRef} />
                <Image ref={moveIconRef} image={moveIcon} draggable x={-100} y={-100} width={24} height={24}/>
              </Layer>
              
            </Stage>
          </div>
        </div>
      ))}
    </div>
  );
};

export default App;